import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';

const DONATION_PAGES_URL = `${appSettings.baseUrl}/donation-pages`;

const SET_ERROR = 'donationPages/SET_ERROR';
const SET_SUCCESS = 'donationPages/SET_SUCCESS';
const SET_LOADING = 'donationPages/SET_LOADING';
const GET_PAGE_STATE = 'donationPages/GET_PAGE_STATE';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const setLoading = createAction(SET_LOADING);

export const getPageState = createAsyncThunk(GET_PAGE_STATE, async () => {
  try {
    const res = await axios.get(DONATION_PAGES_URL);
    return res.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
