import axios from 'axios';
import axiosRetry from 'axios-retry';

import { AUTH_SESSION_KEY } from './constants';
import { removeFalsyValues } from './utils';

axiosRetry(axios, {
  retries: 2,
  retryCondition: (error) => {
    if (error.config.url.includes('accounts/current')) {
      // We're currently only retrying get current account requests
      return true;
    }
    return false;
  },
});

axios.interceptors.request.use((req) => {
  const authToken = sessionStorage.getItem(AUTH_SESSION_KEY);

  if (!authToken) return req;

  req.headers = removeFalsyValues({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
    ...req.headers,
  });

  return req;
});
