import { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  sortDirections,
  reportTypes,
  reportSortBy,
  campaignSendStatusLabels,
  campaignSendStatus,
} from '../core/constants';
import ReportsTable from '../components/reports/ReportsTable';
import AlertMessage from '../components/alerts/AlertMessage';
import appSettings from '../app-settings';
import { sortReports } from '../core/utils';
import Loader from '../components/loader/Loader';
import Sorters from '../components/tables/Sorters';
import Filters from '../components/reports/Filters';
import Chip from '../components/chip/Chip';

const REPORTS_URL = `${appSettings.baseUrl}/reports`;

const filterOptions = [
  { label: campaignSendStatusLabels[campaignSendStatus.sending], value: campaignSendStatus.sending },
  { label: campaignSendStatusLabels[campaignSendStatus.sent], value: campaignSendStatus.sent },
];

const sortingOptions = [
  { label: 'Date', value: reportSortBy.createdAt },
  { label: 'Clicks', value: reportSortBy.click },
  { label: 'Donations', value: reportSortBy.donations },
  { label: 'Amount Raised', value: reportSortBy.amountRaised },
];

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [campaignReports, setCampaignReports] = useState([]);
  const [sortBy, setSortBy] = useState(reportSortBy.createdAt);
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [sendStatus, setSendStatus] = useState('');

  const getCampaignReport = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(REPORTS_URL, { params: { type: reportTypes.single } });
      setCampaignReports(res.data);
    } catch (err) {
      Sentry.captureException(err);
      setError(err?.message || true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getCampaignReport();
  }, [getCampaignReport]);

  const data = useMemo(() => {
    let filteredData = campaignReports;
    if (sendStatus) {
      filteredData = filteredData.filter((re) => re.sendStatus === sendStatus);
    }
    if (sortBy) {
      filteredData = sortReports(filteredData, sortBy, sortDirection);
    }
    return filteredData;
  }, [campaignReports, sendStatus, sortBy, sortDirection]);

  return (
    <div className="w-full space-y-6">
      <div className="space-y-4">
        <div className="flex justify-between space-x-3">
          <h1 className="text-h3">Reports</h1>

          <div className="flex items-center space-x-3">
            <Sorters
              options={sortingOptions}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
            />

            <Filters
              key={sendStatus}
              initialStatus={sendStatus}
              options={filterOptions}
              onSelect={(status) => setSendStatus(status)}
              active={!!sendStatus}
            />
          </div>
        </div>

        {!!sendStatus && (
          <div className="flex flex-wrap items-center space-x-2">
            <Chip
              label={campaignSendStatusLabels[sendStatus]}
              onDelete={() => setSendStatus('')}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          </div>
        )}
      </div>

      <div className="relative">
        <ReportsTable data={data} excludedColumnKeys={['interaction', 'uniqueInteractions']} />

        {loading && <Loader />}
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default Reports;
