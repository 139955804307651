import axios from 'axios';
import mergeWith from 'lodash.mergewith';

import appSettings from '../app-settings';
import { uploadImage } from '../core/utils';
import { DEFAULT_HEADER_IMG_BASE64, DEFAULT_HERO_IMG_BASE64 } from '../core/constants';

const API_URL = `${appSettings.baseUrl}/donation-pages`;

export const updateDonationPage = async (donationPageData) => {
  const {
    images: { headerImage, heroImage, faviconImage },
    ...rest
  } = donationPageData;

  let headerImageData = headerImage.data;
  let heroImageData = heroImage.data;
  // favicon image is optional
  let faviconImageData = faviconImage?.data;
  let customFaviconImage = !!faviconImage?.url;

  const isHeaderImgAccountLogo = headerImage.url.includes('/logo/');

  if (!headerImageData && !(headerImage.url.includes('headerImage') || isHeaderImgAccountLogo)) {
    headerImageData = DEFAULT_HEADER_IMG_BASE64;
  }

  if (!heroImageData && !heroImage.url.includes('heroImage')) {
    heroImageData = DEFAULT_HERO_IMG_BASE64;
  }

  if (headerImageData || heroImageData || faviconImageData) {
    const uploadResponse = await axios.post(`${API_URL}/upload`, {
      headerImage: !!headerImageData,
      heroImage: !!heroImageData,
      faviconImage: !!faviconImageData,
    });

    const {
      uploadUrls: { headerImgPresignedUrl, heroImgPresignedUrl, faviconImgPresignedUrl },
    } = uploadResponse.data;

    if (headerImageData && headerImgPresignedUrl) {
      await uploadImage(headerImgPresignedUrl, headerImageData);
    }

    if (heroImageData && heroImgPresignedUrl) {
      await uploadImage(heroImgPresignedUrl, heroImageData);
    }

    if (faviconImageData && faviconImgPresignedUrl) {
      await uploadImage(faviconImgPresignedUrl, faviconImageData);
      customFaviconImage = true;
    }
  }

  const donationPageState = isHeaderImgAccountLogo
    ? mergeWith({}, rest, { images: { headerImage: { url: headerImage.url } } })
    : rest;

  await axios.post(API_URL, { ...donationPageState, customFaviconImage });
};
