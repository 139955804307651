import { useState, useEffect, useRef, useMemo } from 'react';
import postRobot from 'post-robot';
import * as Sentry from '@sentry/react';
import { IconCopy, IconExternalLink, IconPencil } from '@tabler/icons-react';
import mergeWith from 'lodash.mergewith';

import appSettings from '../app-settings';
import { useAccount } from '../store/account/hooks';
import Button from '../components/buttons/Button';
import { getNetworkError, waitFor } from '../core/utils';
import DonationPageComponent from '../components/donation-page/DonationPageComponent';
import DonationPageEditor from '../components/donation-page/DonationPageEditor';
import AlertMessage from '../components/alerts/AlertMessage';
import { useDonationPage } from '../store/donation-page/hooks';
import { updateDonationPage } from '../api';

const DonationPage = () => {
  const [editorOpen, setEditorOpen] = useState(false);
  const editorRef = useRef(null);
  const { account, getAccount } = useAccount();
  const { loading, error, success, pageState, setLoading, setError, setSuccess, getPageState } = useDonationPage();

  useEffect(() => {
    getPageState();
  }, [getPageState]);

  const onLoadEditor = async () => {
    try {
      const initialState = account?.logoUrl
        ? mergeWith({}, { images: { headerImage: { url: account.logoUrl } } }, pageState || {})
        : pageState;

      await postRobot.send(editorRef.current.contentWindow, 'initEditor', { initialState });
    } catch (error) {
      Sentry.captureException(error);
      setError(true);
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const response = await postRobot.send(editorRef.current.contentWindow, 'saveAndExit');

      await updateDonationPage(response.data);

      if (!account?.donationPageSubdomain) getAccount();

      // Empiric value - page needs some extra time to reflect changes
      await waitFor(2000);

      // Re-fetch page state for reflecting updated state on the editor
      await getPageState();

      setSuccess('Donation page launched successfully!');

      setEditorOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const pageUrl = useMemo(() => {
    // Use hardcoded 'sandbox' subdomain for sandbox accounts to get the iframe preview working
    // Sandbox accounts may not have a donationPageSubdomain set since they didn't go through onboarding
    const subdomain = account?.sandbox ? 'sandbox' : account?.donationPageSubdomain;
    return `https://${subdomain}.${appSettings.donationPageDomain}`;
  }, [account?.donationPageSubdomain, account?.sandbox]);

  const handleCopy = () => {
    navigator.clipboard.writeText(pageUrl);
  };

  return (
    <>
      <div className="flex size-full flex-col">
        <h1 className="text-h3 dark:text-white-100">Donation Page</h1>

        <div className="mb-2 mt-6 flex h-full">
          <main className="flex flex-1 flex-col overflow-hidden rounded-xl bg-white-100 shadow-md">
            <div className="flex items-start justify-between border-b border-gray-50 px-8 py-9">
              <div>
                <h3 className="mb-4 text-h5 font-semibold">Your Donation Page:</h3>
                {!!pageUrl && (
                  <div
                    className="
                        flex
                        w-full
                        items-center
                        justify-between
                        space-x-6
                        rounded-lg
                        bg-gray-10
                        px-4
                        py-2.5
                        text-base
                      "
                  >
                    <p className="flex-1 text-base text-gray-950">{pageUrl}</p>

                    <Button
                      title="Copy"
                      size="sm"
                      onClick={handleCopy}
                      className="
                          !border-0 
                          !bg-gray-10 
                          !p-0 
                          text-sm 
                          !text-gray-800 
                          !shadow-none
                          focus:!ring-0 
                          focus:!ring-offset-0
                          enabled:hover:!text-gray-600
                          enabled:focus:!text-gray-600
                          enabled:active:!text-gray-800
                        "
                      LeftIcon={IconCopy}
                      iconProps={{ size: 16 }}
                    />
                  </div>
                )}
              </div>

              {editorOpen ? (
                <div className="flex items-center space-x-2">
                  <Button title="Save & Exit" color="primary" onClick={onSave} size="sm" />

                  <Button title="Cancel" onClick={() => setEditorOpen(false)} size="sm" />
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <Button title="Edit" onClick={() => setEditorOpen(true)} LeftIcon={IconPencil} size="sm" />

                  <Button
                    title="Preview"
                    href={pageUrl}
                    target="_blank"
                    rel="noreferrer"
                    LeftIcon={IconExternalLink}
                    size="sm"
                  />
                </div>
              )}
            </div>

            <div className="flex-1">
              {editorOpen ? (
                <DonationPageEditor
                  ref={editorRef}
                  pageUrl={`${appSettings.donationPageEditorUrl}/?customerSupportEmail=${account?.customerSupportEmail}`}
                  loading={loading}
                  onLoad={onLoadEditor}
                />
              ) : (
                <DonationPageComponent pageUrl={pageUrl} />
              )}
            </div>
          </main>
        </div>
      </div>

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </>
  );
};

export default DonationPage;
