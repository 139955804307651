import { createSlice } from '@reduxjs/toolkit';

import { setError, setSuccess, setLoading, getPageState } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  pageState: null,
};

const donationPageStore = createSlice({
  name: 'donationPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(setLoading, (state, { payload }) => {
      state.loading = payload;
    });

    builder.addCase(getPageState.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) state.pageState = payload;
    });

    builder.addCase(getPageState.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getPageState.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    });
  },
});

export default donationPageStore.reducer;
