import { useMemo, useRef, useEffect } from 'react';
import { IconExternalLink } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import postRobot from 'post-robot';
import * as Sentry from '@sentry/react';
import mergeWith from 'lodash.mergewith';

import Button from '../buttons/Button';
import { useAccount } from '../../store/account/hooks';
import appSettings from '../../app-settings';
import DonationPageEditor from '../donation-page/DonationPageEditor';
import { useDonationPage } from '../../store/donation-page/hooks';
import StepNavBottom from './StepNavBottom';
import AlertMessage from '../alerts/AlertMessage';
import { getNetworkError, waitFor } from '../../core/utils';
import { updateDonationPage } from '../../api';

const DonationPageStep = ({ onPrev, onNext }) => {
  const editorRef = useRef(null);
  const { account, getAccount } = useAccount();
  const { pageState, loading, error, success, setError, setSuccess, setLoading, getPageState } = useDonationPage();

  useEffect(() => {
    // Edge case - remove /payment-options path from previous step (stripe connect issue)
    window.history.replaceState({}, document.title, '/');
  }, []);

  useEffect(() => {
    // Makes sure we always have the latest page state changes.
    // Use case: deployment failed, but page state was saved, and user goes back and forth on the onboarding steps.
    getPageState();
  }, [getPageState]);

  const onLoadEditor = async () => {
    try {
      const initialState = account?.logoUrl
        ? mergeWith({}, { images: { headerImage: { url: account.logoUrl } } }, pageState || {})
        : pageState;

      await postRobot.send(editorRef.current.contentWindow, 'initEditor', { initialState });
    } catch (error) {
      Sentry.captureException(error);
      setError('Something went wrong when loading the editor!');
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const response = await postRobot.send(editorRef.current.contentWindow, 'saveAndExit');

      await updateDonationPage(response.data);

      if (!account?.donationPageSubdomain) getAccount();

      // Empiric value - page needs some extra time to reflect changes
      await waitFor(2000);

      // Re-fetch page state for reflecting updated state on the editor
      await getPageState();

      setSuccess('Donation page launched successfully!');
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const pageUrl = useMemo(() => {
    return `https://${account?.donationPageSubdomain}.${appSettings.donationPageDomain}`;
  }, [account?.donationPageSubdomain]);

  return (
    <div className="m-auto flex h-full max-w-5xl flex-col space-y-2 px-4 pb-4">
      <div className="space-y-4">
        <div className="flex justify-between space-x-3">
          <h1 className="text-h1 font-semibold">Set up a donation page</h1>

          <div className="mt-1 flex items-center space-x-2">
            <Button title="Save" color="primary" onClick={onSave} size="sm" />

            <Button
              title="Preview"
              href={pageUrl}
              target="_blank"
              rel="noreferrer"
              LeftIcon={IconExternalLink}
              size="sm"
              disabled={!account?.donationPageSubdomain}
            />
          </div>
        </div>
        <p className="text-lg">Create your first donation page.</p>
      </div>

      <div className="flex-1 min-[1472px]:-ml-10">
        <DonationPageEditor
          ref={editorRef}
          pageUrl={`${appSettings.donationPageEditorUrl}/?customerSupportEmail=${account?.customerSupportEmail}`}
          loading={loading}
          onLoad={onLoadEditor}
        />
      </div>

      <StepNavBottom
        onPrev={onPrev}
        onNext={onNext}
        prevDisabled={loading}
        nextDisabled={!account?.donationPageSubdomain}
      />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

DonationPageStep.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default DonationPageStep;
