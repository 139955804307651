import { Outlet } from 'react-router-dom';

import Navbar from '../components/navbar/Navbar';
import Header from '../components/header/Header';

const Layout = () => {
  return (
    <>
      <Header />
      <div className="flex grow overflow-y-hidden">
        <Navbar />
        <div className={`grow overflow-auto px-8 py-6`}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
