import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';

import { useAccount } from '../store/account/hooks';
import { formValidation as accountValidation } from '../components/account-info/utils';
import { ONBOARDING_STEPS } from '../core/constants';
import { useDomains } from '../store/domains/hooks';
import { usePaymentOptions } from '../store/payment-options/hooks';
import { useDonationPage } from '../store/donation-page/hooks';
import { useImports } from '../store/imports/hooks';

const isAccountInfoComplete = (account) => {
  try {
    accountValidation.validateSync(account);
    return true;
  } catch (err) {
    return false;
  }
};

const useOnboarding = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { account, getAccount } = useAccount();
  const { authenticatedDomain, getDomains } = useDomains();
  const { isStripeAccountConnected, isStripeConnectionComplete, getStripeAccount } = usePaymentOptions();
  const { pageState, getPageState } = useDonationPage();
  const { imports, onboarding: importOnboarding, getImports } = useImports();

  const getUserAccountData = useCallback(async () => {
    try {
      // .unwrap() will return a new Promise that either has the actual action.payload value
      // from a fulfilled action, or throws an error if it's the rejected action.
      // https://redux.js.org/tutorials/essentials/part-5-async-logic#checking-thunk-results-in-components
      const tenantAccount = await getAccount().unwrap();

      if (!tenantAccount?.onboarded) {
        // If any of this actions fail, error will be handled by redux
        await Promise.all([getStripeAccount(), getDomains(), getPageState(), getImports()]);
      }
    } catch (err) {
      // Get current account error
      Sentry.captureException(err);
      setError(true);
    }
    setLoading(false);
  }, [getAccount, getStripeAccount, getDomains, getPageState, getImports]);

  useEffect(() => {
    getUserAccountData();
  }, [getUserAccountData]);

  const currentOnboardingStep = useMemo(() => {
    if (account?.onboarded || account?.sandbox) {
      // Onboarding has been successfully completed
      return ONBOARDING_STEPS.completed;
    }

    // In the future we may skip the subscription step if invoices are already paid
    // For now we'll always go there before the onboarding call step
    if (!account.onboardingCall) {
      return ONBOARDING_STEPS.subscription;
    }

    if (!isAccountInfoComplete(account)) {
      return ONBOARDING_STEPS.accountInfo;
    }

    if (!isStripeAccountConnected || !isStripeConnectionComplete) {
      return ONBOARDING_STEPS.stripeAccount;
    }

    if (!pageState || !account?.donationPageSubdomain) {
      return ONBOARDING_STEPS.donationPage;
    }

    if (!authenticatedDomain) {
      return ONBOARDING_STEPS.domainAuthentication;
    }

    if (!imports.length || importOnboarding) {
      return ONBOARDING_STEPS.importContacts;
    }

    return ONBOARDING_STEPS.congrats;
  }, [
    account,
    isStripeAccountConnected,
    isStripeConnectionComplete,
    authenticatedDomain,
    pageState,
    imports,
    importOnboarding,
  ]);

  return { currentOnboardingStep, loading, error };
};

export default useOnboarding;
