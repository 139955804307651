import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import appSettings from '../app-settings';
import { authenticate, getUserRole, hasValidSession } from '../core/utils';
import Spinner from '../components/loader/Spinner';
import ProtectedComponent from './ProtectedComponent';
import { useAccount } from '../store/account/hooks';

const { redirectUri, appClient } = appSettings;

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => hasValidSession());
  const navigate = useNavigate();
  const { setUserRole } = useAccount();

  const initAuthentication = useCallback(async () => {
    const isAuth = await authenticate(redirectUri, appClient, navigate);
    setIsAuthenticated(isAuth);

    if (!isAuth) return;

    const role = getUserRole();
    setUserRole(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) return;

    initAuthentication();
  }, [isAuthenticated, initAuthentication]);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center space-y-8">
        <h2 className="text-h2 font-semibold">Checking login status...</h2>
        <Spinner className="size-[34px]" />
      </div>
    );
  }

  if (children) {
    return <ProtectedComponent>{children}</ProtectedComponent>;
  }

  return <ProtectedComponent />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ProtectedRoute;
