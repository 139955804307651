import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { setError, setSuccess, setLoading, getPageState } from './actions';

export const useDonationPage = () => {
  const donationPageState = useSelector((state) => state.donationPage, shallowEqual);

  return {
    ...donationPageState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    setLoading: useActionCreator(setLoading),
    getPageState: useActionCreator(getPageState),
  };
};
