import '@stripe/connect-js';
import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui';

import useOnboarding from '../hooks/useOnboarding';
import { useSegments } from '../store/segments/hook';
import { useTags } from '../store/tags/hook';
import Spinner from '../components/loader/Spinner';
import { ONBOARDING_STEPS } from '../core/constants';
import Onboarding from '../pages/onboarding';
import { signOut } from '../core/utils';
import Layout from './Layout';

export const ProtectedComponent = ({ children }) => {
  const { getTags } = useTags();
  const { getSegments } = useSegments();
  const { currentOnboardingStep, loading, error } = useOnboarding();

  const getInitialData = useCallback(async () => {
    await Promise.all([getTags(), getSegments()]);
  }, [getTags, getSegments]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  if (loading) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center space-y-8">
        <h2 className="text-h2 font-semibold">Loading your account...</h2>
        <Spinner className="size-[34px]" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center space-y-8">
        <div className="space-y-3 text-center">
          <h2 className="text-h2 font-semibold">Your account failed to load.</h2>
          <p className="font-medium">Please contact support for assistance.</p>
        </div>
        <Button title="Sign Out" color="primary" onClick={signOut} />
      </div>
    );
  }

  if (currentOnboardingStep !== ONBOARDING_STEPS.completed) {
    return <Onboarding initialStep={currentOnboardingStep} />;
  }

  if (children) {
    return children;
  }

  return <Layout />;
};

ProtectedComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ProtectedComponent;
