import { useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../loader/Loader';

const DonationPageComponent = ({ pageUrl }) => {
  const [loading, setLoading] = useState(true);
  // helps mitigate cache issues
  const [name] = useState(Date.now().toString());

  return (
    <div className="relative size-full bg-white-100">
      <iframe
        name={name}
        src={`${pageUrl}?name=${name}`}
        width="100%"
        height="100%"
        className="shadow-md"
        onLoad={() => setLoading(false)}
      />
      {loading && <Loader />}
    </div>
  );
};

DonationPageComponent.propTypes = {
  pageUrl: PropTypes.string.isRequired,
};

export default DonationPageComponent;
